/* header start */
.header
  @extend %position-absolute
  top: 0
  left: 0
  @extend %w-100
  z-index: 2
  &-fixed
    position: fixed
    .header-bottom
      padding: 0
      @extend %bg-white 
      box-shadow: 0 3px 5px rgba(#000, 0.1)
  &-bottom
    background-color: transparent
    padding: rem(10px) 0
    +media(1199px)
      padding: rem(10px) 0
  .site-logo
    img
      max-width: rem(250px)
      max-height: rem(65px)
      +media(1199px)
        max-width: rem(150px)
        max-height: rem(55px)
    &.site-title
      font-size: rem(24px)
      @extend %text-base
  .main-menu
    @extend %align-center
    margin-left: rem(70px)
    counter-reset: section
    +media(1199px)
      margin-left: 0
      padding: rem(15px) 0
      align-items: start
    >.nav-item
      > .nav-link
        @extend %position-relative 
        &::after 
          @extend %position-absolute
          top: 13px
          right: 25px
          counter-increment: section
          content: "0"counter(section)
          @extend %text-p  
          font-size: rem(11px)
          opacity: 0.45 
    
    .nav-item
      position: relative
      +media(1199px)
        width: 100% 
        border-bottom: 1px solid rgba(#ffffff, 0.15)
      &.has_children
        position: relative
        &.open
          >.sub-menu
            display: block
        >.nav-link
          +media(1199px)
            display: block
        &:hover
          >.nav-link
            @extend %text-base  
      .nav-link
        font-size: rem(18px)
        font-family: $heading-font
        font-weight: 600
        padding: rem(28px) rem(25px)
        @extend %text-h 
        +media(1199px)
          color: #fff
          padding: rem(8px) 0
          display: block
        &:hover,&:focus
          @extend %text-base 
      .sub-menu
        position: absolute
        width: 260px
        top: 105%
        left: 0
        z-index: 9999
        @extend %bg-white
        padding: rem(10px) 0
        box-shadow: 0px 5px 15px 0 rgba(0,0,0,0.1)
        @extend %bs-5
        @extend  %transition
        opacity: 0
        visibility: hidden
        +media(1199px)
          opacity: 1
          visibility: visible
          display: none
          position: static
          +transition(none)
          width: 100%
          background-color: $base-color-two
          border: none
          padding-left: rem(15px)
          padding-top: 0
        .nav-item
          border-bottom: 1px dashed rgba(#000, 0.15)
          +media(1199px)
            border-color: rgba(#fff, 0.15)
          &:last-child
            border-bottom: none
          .nav-link
            padding: rem(6px) rem(25px)
            display: block
            color: $para-color
            position: relative
            font-size: rem(14px)
            text-transform: capitalize
            @extend  %transition
            +media(1199px)
              color: #fff
              padding: rem(8px) rem(0)
            &:hover
              @extend %text-base
      &:hover > .sub-menu
        top: 100%
        opacity: 1
        visibility: visible
    .sub-menu
      .nav-item.has_children
        >a
          &::before
            top: 9px
            right: 8px
        > .sub-menu
          left: 100%
          top: 0
          +media(1199px)
            padding-left: 15px

.navbar-collapse
  +media(1199px)
    margin-top: 20px !important
    background-color: $base-color-two
    padding: 0 rem(30px) rem(20px) rem(30px)
  +media(767px)
    max-height: rem(320px)
    overflow: auto
.navbar-toggler
  padding: 0
  border: none
  &:focus
    outline: none
    box-shadow: none
.menu-toggle
  margin: 10px 0
  position: relative
  display: block
  width: rem(35px)
  height: rem(20px)
  cursor: pointer
  background: transparent
  border-top: 2px solid
  border-bottom: 2px solid
  @extend %text-white
  font-size: 0
  +transition(all 0.25s ease-in-out)
  cursor: pointer
.menu-toggle:before, .menu-toggle:after
  content: ''
  display: block
  width: 100%
  height: 2px
  position: absolute
  top: 50%
  left: 50%
  background: currentColor
  +transform(translate(-50%, -50%))
  transition: -webkit-transform 0.25s ease-in-out
  -webkit-transition: -webkit-transform 0.25s ease-in-out
  -o-transition: -webkit-transform 0.25s ease-in-out
  transition: transform 0.25s ease-in-out
  -moz-transition: -webkit-transform 0.25s ease-in-out
  -ms-transition: -webkit-transform 0.25s ease-in-out
  +media(1199px)
    background-color: #fff
// create animation for when menu is fixed
.animated
  -webkit-animation-duration: 1s
  animation-duration: 1s
  -webkit-animation-fill-mode: both
  animation-fill-mode: both
.fadeInDown
  -webkit-animation-name: fadeInDown
  animation-name: fadeInDown
+keyframes (fadeInDown)
  0%
    opacity: 0
    -webkit-transform: translateY(-20px)
    transform: translateY(-20px)
  100%
    opacity: 1
    -webkit-transform: translateY(0)
    transform: translateY(0)
/* header end */
