/* experience section css start */
.experience-section 
    padding-bottom: rem(150px)

.experience-item 
    @extend %d-flex 
    @extend %align-center
    justify-content: space-between
    &.prsent-company
        background-color: rgba($base-color, 0.05)
        border: 1px solid rgba(#000, 0.08)
        @extend %bs-8
    .left 
        @extend %d-flex 
        @extend %align-center
        padding: rem(15px)
        img 
            max-width: 120px
            margin-right: rem(15px)
        .title 
            font-size: rem(20px)
    .right 
        padding: rem(15px)
        text-align: right 
        p 
            font-size: rem(16px)
/* experience section css end */