/* modal css start */
.modal 
  z-index: 999999
.modal-open 
  overflow: hidden
  overflow-y: auto
  padding-right: 0 !important

.btn-close 
  width: 18px
  height: 18px 
  background-color: $danger 
  opacity: 1
  @extend %d-flex
  @extend %align-center 
  @extend %justify-center 
  &:hover 
    opacity: 1
  &:focus 
    box-shadow: none 

.modal
  &-content 
    background-color: $section-bg 
  &-header
    border-bottom-color: $border-color 
  &-footer 
    border-top-color: $border-color 
  &.fade 
    .modal-dialog
      transform: scale(0.85, 0.85) translate(0)
  &.show 
    .modal-dialog
      transform: scale(1, 1) translate(0)

body .header, 
body .main-wrapper
  @extend %transition 

// body.modal-open .header, 
// body.modal-open .main-wrapper
//   -webkit-filter: blur(10px)
//   -moz-filter: blur(10px)
//   -o-filter: blur(10px)
//   -ms-filter: blur(10px)
//   filter: blur(10px)

/* modal css end */