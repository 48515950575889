/* product section css start */
.product-section 
  padding-bottom: rem(120px) 

.product-item
  padding: rem(40px)
  @extend %bs-10 
  background: linear-gradient(180deg, rgba($grad-one, 0.05), rgba($grad-two, 0.13))
  background-color: #fff
  // box-shadow: 0 25px 50px 0 #00000016
  .title 
    font-size: rem(24px)
  .product-meta 
    @extend %d-flex 
    @extend %align-center 
    margin: rem(-5px) rem(-15px)
    li 
      padding: rem(5px) rem(15px)
      @extend %position-relative 
      &:last-child
        &::before 
          display: none 
      &::before 
        @extend %position-absolute 
        content: ''
        top: 50% 
        right: -4px
        margin-top: -4px 
        width: 8px
        height: 8px
        @extend %bs-50 
        background-color: rgba(#000, 0.6)
  img 
    @extend %bs-10
    margin-bottom: rem(35px)
/* product section css end */