%position-relative
  position: relative
%position-absolute
  position: absolute
%position
  +position
%positionTwo
  +positionTwo

%z-index-p
  z-index: 1
%z-index-c
  z-index: -1
  
%transition
  -webkit-transition: all 0.3s
  -o-transition: all 0.3s
  transition: all 0.3s

%base-color
  background-color: $base-color
%base-color-two
  background-color: $base-color-two
%bg-white
  background-color: #fff  
%bg-gradi 
  +grad-one

%text-base 
  color: $base-color
%text-base-two
  color: $base-color-two
%text-white
  color: #fff
%text-h
  color: $heading-color 
%text-p
  color: $para-color 

%obj-fit
  +object-fit

%d-flex 
  +d-flex 
%justify-center
  justify-content: center
%align-center
  align-items: center
%d-inline-block 
  display: inline-block
%d-inline-flex
  display: inline-flex
%overflow-hidden 
  overflow: hidden

%w-100
  width: 100%
%h-100
  height: 100%

%trans-y
  +transform(translateY(-5px))
%text-center 
  text-align: center

%bs-5 
  +border-radius(5px)
%bs-8
  +border-radius(8px)
%bs-10
  +border-radius(10px)
%bs-50
  +border-radius(50%)