// .faq-wrapper 
//   counter-reset: faq
// .faq-single 
//   border-bottom: 1px solid rgba(#fff, 0.05)
//   &:last-child
//     border-bottom: none 
//   &-header
//     cursor: pointer
//     padding: rem(20px) 0
//     @extend %position-relative
//     @extend %transition 
//     &::after 
//       @extend %position-absolute 
//       content: "\f107"
//       top: 21px
//       right: 20px 
//       font-family: 'Line Awesome Free'
//       font-weight: 900
//       @extend %transition 
//       font-size: rem(14px)
//   &-body 
//     display: none 
//     padding: rem(20px) 0
//   .title 
//     font-size: rem(22px)
//     @extend %transition 
//     padding-right: 20px
//     font-weight: 500  
//     &::before 
//       counter-increment: faq
//       content: "Q " counter(faq) "."
//       margin-right: rem(6px)
//       color: darken(#fff, 40%)
//     +media(420px)
//       font-size: rem(16px)
//   &.active 
//     .title 
//       @extend %text-base
//     .faq-single-header
//       &::after 
//         +transform(rotate(-180deg))
//         @extend %text-base 

.accordion-item 
  border: none 
  border-bottom: 1px solid $border-color
  &:last-child
    border-bottom: none 
  .accordion-header 
    .accordion-button 
      font-size: rem(17px)
      font-weight: 500 
      box-shadow: none 
      padding: rem(15px) 0
      font-weight: 600 
      @extend %text-h 
      &:not(.collapsed) 
        @extend %text-h  
        @extend %bg-white 
  .accordion-body 
    padding: 0 0 rem(18px) 0