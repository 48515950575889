/* footer section css start */
.footer-section
    padding-top: rem(150px)
    padding-bottom: rem(50px)
    @extend %overflow-hidden
    @extend %position-relative 
    @extend %z-index-p 
    &::before 
        @extend %position-absolute 
        content: '' 
        top: 0 
        left: 50% 
        width: 1200px
        height: 1200px
        margin-left: -600px
        background: linear-gradient(to bottom, rgba($base-color, 0.1), #fff) 
        @extend %z-index-c
        @extend %bs-50 

.social-item 
    border-top: 1px solid rgba(#2b2b2b, 0.65)
    padding-top: rem(12px)
    @extend %d-flex 
    @extend %align-center 
    justify-content: space-between
    .content 
        padding-right: rem(15px)
    .icon 
        width: 48px 
        height: 48px 
        @extend %bg-white 
        @extend %d-flex 
        @extend %align-center 
        @extend %justify-center 
        @extend %bs-50 
        box-shadow: 0 0 5px rgba(#000, 0.1)
        i 
            font-size: rem(24px)
/* footer section css end */