/* banner section start */
.hero-section 
  @extend %position-relative 
  // min-height: 100vh  
  @extend %d-flex 
  flex-flow: column
  @extend %justify-center
  padding-top: rem(170px)
  padding-bottom: rem(150px) 
  @extend %z-index-p 
  .left-el 
    @extend %position-absolute 
    top: 0 
    left: 0 
    @extend %z-index-c
    opacity: 0.45
  .hero-tag-line 
    font-size: rem(18px)
    @extend %position-relative
    margin-bottom: rem(10px) 
    font-weight: 500 
    @extend %position-relative 
    padding-left: rem(40px)
    &::before 
      @extend %position-absolute 
      content: '' 
      top: 50% 
      left: 0
      width: 30px 
      height: 2px 
      @extend %base-color 
  .hero-title 
    font-size: rem(84px)
    font-weight: 300
    span 
      font-weight: 700 

.hero-description 
  max-width: 505px
  margin-top: rem(70px)
  p 
    font-size: rem(18px)
    font-weight: 300 
    b 
      font-weight: 600 

.hero-thumb 
  // float: left 
  // margin-left: rem(70px)
  // width: 105% 
  width: 500px 
  height: 650px 
  +border-radius(999px)
  // @extend %bs-50 
  @extend %overflow-hidden 
  border: 15px solid #e5e5e5 
  img 
    @extend %w-100 
    @extend %h-100
    @extend %obj-fit  

  
/* banner section end */