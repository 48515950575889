@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-SemiBoldItalic.woff2') format("woff2"), url('../fonts/Gilroy-SemiBoldItalic.woff') format("woff")
  font-weight: 600
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-BoldItalic.woff2') format("woff2"), url('../fonts/Gilroy-BoldItalic.woff') format("woff")
  font-weight: bold
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-MediumItalic.woff2') format("woff2"), url('../fonts/Gilroy-MediumItalic.woff') format("woff")
  font-weight: 500
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-LightItalic.woff2') format("woff2"), url('../fonts/Gilroy-LightItalic.woff') format("woff")
  font-weight: 300
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-UltraLightItalic.woff2') format("woff2"), url('../fonts/Gilroy-UltraLightItalic.woff') format("woff")
  font-weight: 200
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-ExtraBoldItalic.woff2') format("woff2"), url('../fonts/Gilroy-ExtraBoldItalic.woff') format("woff")
  font-weight: 800
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-ExtraBold.woff2') format("woff2"), url('../fonts/Gilroy-ExtraBold.woff') format("woff")
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Regular.woff2') format("woff2"), url('../fonts/Gilroy-Regular.woff') format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Heavy.woff2') format("woff2"), url('../fonts/Gilroy-Heavy.woff') format("woff")
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Thin.woff2') format("woff2"), url('../fonts/Gilroy-Thin.woff') format("woff")
  font-weight: 100
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Bold.woff2') format("woff2"), url('../fonts/Gilroy-Bold.woff') format("woff")
  font-weight: bold
  font-style: normal

@font-face
  font-family: '../fonts/Gilroy-RegularItalic'
  src: url('../fonts/Gilroy-RegularItalic.woff2') format("woff2"), url('../fonts/Gilroy-RegularItalic.woff') format("woff")
  font-weight: normal
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-BlackItalic.woff2') format("woff2"), url('../fonts/Gilroy-BlackItalic.woff') format("woff")
  font-weight: 900
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-HeavyItalic.woff2') format("woff2"), url('../fonts/Gilroy-HeavyItalic.woff') format("woff")
  font-weight: 900
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-ThinItalic.woff2') format("woff2"), url('../fonts/Gilroy-ThinItalic.woff') format("woff")
  font-weight: 100
  font-style: italic

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-SemiBold.woff2') format("woff2"), url('../fonts/Gilroy-SemiBold.woff') format("woff")
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-UltraLight.woff2') format("woff2"), url('../fonts/Gilroy-UltraLight.woff') format("woff")
  font-weight: 200
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Black.woff2') format("woff2"), url('../fonts/Gilroy-Black.woff') format("woff")
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Medium.woff2') format("woff2"), url('../fonts/Gilroy-Medium.woff') format("woff")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Light.woff2') format("woff2"), url('../fonts/Gilroy-Light.woff') format("woff")
  font-weight: 300
  font-style: normal