.btn 
  padding: rem(12px) rem(30px)
  @extend %bs-8
  font-weight: 500 
  &:focus 
    box-shadow: none 
  &.btn-md
    padding: rem(10px) rem(20px)
  &.btn-sm 
    padding: rem(8px) rem(20px)

.ps-btn 
  // @extend %base-color 
  background: linear-gradient(to right, $grad-one, $grad-two)
  @extend %text-white
  &:hover 
    @extend %text-white 
.ps-btn2
  @extend %base-color-two
  @extend %text-white
  &:hover 
    @extend %text-white 

.ps-btn-outline 
  @extend %text-base 
  @extend %bg-white 
  border: 2px solid $base-color
  &:hover 
    @extend %base-color
    @extend %text-white 
.ps-btn2-outline
  @extend %text-base-two 
  @extend %bg-white 
  border: 2px solid $base-color-two
  &:hover 
    @extend %base-color-two 
    @extend %text-white 

.text-link 
  @extend %text-base 
