+keyframes(spin)
  0%
    +transform(rotate(0deg))
  100% 
    +transform(rotate(360deg))

+keyframes(upDown)
  0% 
    top: 0%
  25% 
    top: 50% 
  50% 
    top: 100% 
  75% 
    top: 50%
  100%
    top: 0%

+keyframes(downUp)
  0% 
    bottom: 0%
  25% 
    bottom: 50% 
  50% 
    bottom: 100% 
  75% 
    bottom: 50%
  100%
    bottom: 0%