=transform($property)
  -webkit-transform: $property
  -ms-transform: $property
  transform: $property

=border-radius($property)
  border-radius: $property
  -webkit-border-radius: $property
  -moz-border-radius: $property
  -ms-border-radius: $property
  -o-border-radius: $property

=transition($property)
  -webkit-transition: $property
  -o-transition: $property
  transition: $property

=flexWidth($property)
  flex: 0 0 $property
  -ms-flex: 0 0 $property
  max-width: $property

=d-flex 
  display: -ms-flexbox
  display: flex
  -ms-flex-wrap: wrap
  flex-wrap: wrap

=position
  position: absolute
  top: 0
  left: 0
  width: 100% 
  height: 100%

=positionTwo
  position: absolute
  content: ''
  top: 0
  left: 0
  width: 100% 
  height: 100%

=object-fit 
  object-fit: cover 
  -o-object-fit: cover 
  object-position: center 
  -o-object-position: center

=animation($property)
  animation: $property
  -webkit-animation: $property
  -moz-animation: $property

=font($size, $weight, $transform)
  font-size: $size
  font-weight: $weight
  text-transform: $transform

=text-line-1
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
=text-line-2
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
=text-line-3
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis

// media query mixin 
=media($value)
  @media (max-width: $value)
    @content

// keyframes mixin
=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

=placeholder($value)
  &::-webkit-input-placeholder
    color: $value
  &::-moz-placeholder
    color: $value
  &:-ms-input-placeholder
    color: $value
  &:-moz-placeholder
    color: $value

=color($bg-color, $text-color) 
  background-color: $bg-color
  color: $text-color

=grad-one
  background: $base-color
  background: -webkit-linear-gradient(135deg,#ff690f 0%,#ee4719 100%)
  background: linear-gradient(135deg,#ff690f 0%,#ee4719 100%)