/* dahsboard css start */
.user-widget 
  @extend %bg-white 
  @extend %bs-8 
  border: 1px solid lighten($border-color, 5%)
  @extend %text-center 
  &-body 
    padding: 25px
  .thumb 
    width: 150px
    height: 150px
    @extend %overflow-hidden 
    @extend %bs-50 
    margin-left: auto
    margin-right: auto 
    img 
      @extend %w-100 
      @extend %h-100 
      @extend %obj-fit 
  &-footer 
    padding: rem(10px) rem(15px)
    border-top: 1px solid lighten($border-color, 5%)

.wallet-icon 
  width: 60px
  height: 60px
  @extend %position-relative 
  @extend %d-flex 
  @extend %align-center
  @extend %justify-center 
  font-size: rem(32px)
  @extend %text-base 
  margin-left: auto
  margin-right: auto 
  @extend %z-index-p 
  &::before 
    @extend %position-absolute 
    content: ''
    top: 0
    left: 0
    @extend %w-100 
    @extend %h-100 
    @extend %base-color 
    opacity: 0.115
    @extend %z-index-c
    @extend %bs-50 

.card-1 
  @extend %bg-white 
  padding: rem(15px) rem(15px) rem(10px) rem(15px)
  border: 1px solid lighten($border-color, 5%)
  @extend %bs-8 
  @extend %h-100 
  p 
    +media(1399px)
      font-size: rem(13px)
  .view-all 
    margin-top: rem(12px)
    padding-top: rem(7px)
    border-top: 1px solid lighten($border-color, 5%)
    a 
      @extend %d-flex 
      @extend %align-center 
      justify-content: space-between
      @extend %text-base 
/* dahsboard css end */

#regForm 
  padding: 0

input
  padding: 10px
  width: 100%
  font-size: 17px
  font-family: Raleway
  border: 1px solid #aaaaaa

  &.invalid
    background-color: #ffdddd

.tab
  display: none

#prevBtn
  background-color: #bbbbbb

.steps-wrapper 
  @extend %bg-white 
  padding: rem(25px) 0

.all-steps 
  @extend %d-flex 
  .step
    width: calc(100% / 3)
    @extend %text-p 
    font-weight: 600 
    @extend %d-flex 
    @extend %align-center 
    @extend %justify-center 
    color: #8f9ebe 
    +media(575px)
      flex-direction: column
      justify-content: center
      text-align: center
    span 
      width: 30px
      height: 30px
      background-color: #f0f0f0
      @extend %d-inline-flex 
      @extend %align-center 
      @extend %justify-center 
      @extend %bs-50 
      margin-right: rem(6px)
    &.active
      color: $dark 
      span 
        background-color: $primary 
        @extend %text-white 
    &.finish
      color: $success
      span 
        background-color: $success 
        @extend %text-white 

.thanks-message
  display: none


.custom-transfer-field
  &.style-two 
    .form-control 
      height: 50px 
      padding: rem(10px) rem(15px) 
      border: 1px solid $border-color 