/* table css start */
.site-table
  margin-bottom: 0
  thead 
    th 
      // border: none !important
      padding: rem(8px) rem(20px)
      @extend %text-p
      font-weight: 600 
      font-size: rem(14px) 
      text-transform: uppercase
      // text-align: center 
      font-weight: 500 
      vertical-align: middle
      background-color: $section-bg
      border-bottom: 1px solid $border-color !important
      &:first-child
        text-align: left
        +border-radius(8px 0 0 8px)
      &:last-child
        text-align: right
        +border-radius(0 8px 8px 0)
  tbody 
    td 
      border-top: none 
      border-bottom: 1px solid lighten($border-color, 2%)
      padding: rem(10px) rem(20px)
      color: $para-color
      // text-align: center
      vertical-align: middle
      &:first-child
        text-align: left
      &:last-child
        text-align: right
    tr        
      &:last-child
        td 
          border-bottom: none 
.table-user 
  @extend %d-inline-flex 
  @extend %align-center 
  img  
    width: 45px 
    height: 45px 
    @extend %bs-50 
  .name 
    padding-left: rem(15px)
// table responsive 
[data-caption]
  position: relative
  &::before 
    position: absolute
    content: attr(data-caption)
    font-weight: 700 
    @extend %text-h 
    top: 0 
    left: 0
    padding: rem(13px) rem(15px)
    display: none
    font-size: rem(14px)
.site-table
  +media(991px)
    thead 
      display: none
    tbody 
      tr
        &:nth-child(odd)
          background-color: darken($body-bg, 3%)
        &:last-child
          td 
            border-top: 1px solid rgba(#000, 0.08)
        td 
          padding-right: 15px
          &:last-child
            padding-right: 15px
    tr 
      th, td 
        display: block
        padding-left: 45%!important
        text-align: right!important
        &:first-child
          border-top: none !important
    [data-caption]
      &::before
        display: block
/* table css end */