// Add badges class name and color through sass loop
// $colors: "primary" $primary, "secondary" $secondary, "success" $success, "danger" $danger, "warning" $warning, "info" $info, "light" $light, "dark" $dark, "base" $base-color

// @each $name, $color in $colors 
//   .badge-#{$name}
//     background-color: rgba($color, 0.15)
//     border: 1px solid $color
//     color: $color


.badge
  padding: 0 0 0 15px
  position: relative
  color: #052554
  background-color: transparent
  font-size: 14px

  &::before
    position: absolute
    content: ''
    top: 50%
    left: 0
    width: 10px
    height: 10px
    background-color: #052554
    border-radius: 50%
    margin-top: -5px

.badge-primary
  color: #6777EF

  &::before
    background-color: #6777EF

.badge-info
  color: #0bb2d4

  &::before
    background-color: #0bb2d4

.badge-warning
  color: #eb6709

  &::before
    background-color: #eb6709

.badge-danger
  color: #ff4c52

  &::before
    background-color: #ff4c52

.badge-success
  color: #11c26d

  &::before
    background-color: #11c26d