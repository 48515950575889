/* background color css start */
.bg--primary
  background-color: $primary !important
.bg--secondary
  background-color: $secondary !important
.bg--success
  background-color: $success !important
.bg--danger
  background-color: $danger !important
.bg--warning
  background-color: $warning !important
.bg--info
  background-color: $info !important
.bg--dark
  background-color: $dark !important
.bg--light 
  background-color: $light !important
.bg--base
  background-color: $base-color !important


.bg-light-1
  background-color: #FFE2EC !important
.bg-light-2
  background-color: #E8EFFF !important
.bg-light-3 
  background-color: #F3FBE8 !important
.bg-light-4 
  background-color: #FEEBEB !important
.bg-light-5 
  background-color: #F0E9FF !important
.bg-light-6 
  background-color: #E5F8F2 !important
/* background color css end */