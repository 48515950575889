/* expertise section css start */
.expertise-section
  padding-bottom: rem(150px)
  

.expertise-wrapper 
  margin-top: 120px 
  @extend %d-flex 
  align-items: flex-start
  padding: 0 rem(100px)
  justify-content: space-between 

.expertise-card 
  @extend %text-center 
  width: calc((100% / 3) - 20px)
  padding: rem(50px) rem(40px)
  @extend %bs-5 
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0),#fff)
  background-color: transparent
  box-shadow: 0 40px 70px 0 #0000000d
  margin-bottom: rem(30px)
  &:nth-of-type(6n + 1)
    > i 
      background-image: linear-gradient(180deg,#e08bfc,#7357fb)
      box-shadow: 0 15px 30px 0 #65000014
    .title 
      color: #7d6e9b
  &:nth-of-type(6n + 2)
    > i 
      background-image: linear-gradient(180deg,#00aeef,#0f5ec9)
      box-shadow: 0 15px 30px 0 #000e6514
    .title
      color: #707dac
  &:nth-of-type(6n + 3)
    > i 
      background-image: linear-gradient(180deg,#24eefc 3%,#236dfc 98%)
      box-shadow: 0 15px 30px 0 #10006514
    .title
      color: #637595
  &:nth-of-type(6n + 4)
    > i 
      background-image: linear-gradient(180deg,#ff6167,#ff0e27)
      box-shadow: 0 15px 30px 0 #65000014
    .title
      color: #866e7d
  &:nth-of-type(6n + 5)
    > i 
      background-image: linear-gradient(180deg,#00e31d,#00c0ce)
      box-shadow: 0 15px 30px 0 #00650614
    .title
      color: #607e70
  &:nth-of-type(6n + 6)
    > i 
      background-image: linear-gradient(180deg,#fae000,#f2557a)
      box-shadow: 0 15px 30px 0 #654c0014
    .title
      color: #897c66

  &:nth-of-type(3n + 2)
    margin-top: -85px 
    
    
  > i
    width: 75px
    height: 75px 
    background-color: $base-color 
    @extend %text-white 
    display: inline-flex
    @extend %justify-center 
    @extend %align-center 
    @extend %bs-50 
    font-size: rem(36px) 
    margin-bottom: rem(20px)
  .details 
    font-size: rem(18px) 
    margin-top: rem(20px) 
  .text-link 
    margin-top: rem(25px) 
/* expertise section css end */