.text-primary
  color: $primary !important

.text-secondary
  color: $secondary !important

.text-success
  color: $success !important

.text-danger
  color: $danger !important

.text-warning
  color: $warning !important

.text-info
  color: $info !important

.text-dark
  color: $dark !important

.text-muted
  color: $muted !important
  
.site-color 
  color: $base-color !important

.site-color2
  color: $base-color-two !important